import React from "react"
import styled from "styled-components"

import Image from "./image"
import { FlexContainerHorizontal, TwoColumnsGrid } from "./containers"

const NewsletterContainer = styled.div`
  padding: 64px 24px;
  min-width: 100%;
  background-color: #fafafa;

  & form {
    background-color: inherit;
    padding: 0;
    margin: 0 0 14px 0;

    & input {
      padding: 4px;
      background-color: inherit;
      box-shadow: 0 1px 0 0 #d4d4d4;
    }

    & button {
      font-size: 16px;
      padding: 12px 24px;
    }
  }

  & p {
    color: rgb(104, 95, 116);
    font-size: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const NewsletterImage = styled(FlexContainerHorizontal)`
  width: 100%;
  justify-content: center;

  * {
    max-width: 264px;
  }
`

const NewsletterContent = styled.div`
  display: grid;
  grid-row-gap: 24px;
  grid-column-gap: 104px;
  grid-template-columns: 1fr 560px;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    grid-template-columns: auto;
  }
`

const EMAIL_TEST_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

async function _submit(values, FORM_ID) {
  const response = await fetch(
    `https://api.convertkit.com/v3/forms/${FORM_ID}/subscribe`,
    {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        charset: "utf-8",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
  return await response.json()
}

const FormContainer = styled(TwoColumnsGrid)`
  grid-template-columns: 3fr 1fr;
  align-items: center;
  grid-gap: 14px;

  @media (max-width: 960px) {
    grid-template-columns: auto;
  }
`

class ConvertKitForm extends React.Component {
  state = {
    email: "",
    subscribed: false,
    submittingForm: false,
  }

  _handleChange = event => {
    event.preventDefault()
    const updatedState = { ...this.state }
    const { name, value } = event.target
    updatedState[name] = value
    this.setState(updatedState)
  }

  _handleSubmit = event => {
    event.preventDefault()
    const { email } = this.state
    if (!EMAIL_TEST_REGEX.test(email)) {
      alert(`Indirizzo email invalido!`)
      return
    }
    const values = {
      api_key: "IzRINeFPbtZQlQGVDZNiyQ",
      email,
    }
    const FORM_ID = "1167365"
    this.setState({ ...this.state, submittingForm: true })
    _submit(values, FORM_ID)
      .then(responseJSON => {
        const { status, error } = responseJSON
        if (status === "failed" || error) {
          this.setState({ ...this.state, submittingForm: false })
          alert(JSON.stringify(responseJSON))
          return
        }
        this.setState({
          ...this.state,
          email: "",
          subscribed: true,
          submittingForm: false,
        })
        console.log(responseJSON)
      })
      .catch(error => {
        alert(JSON.stringify(error))
      })
  }

  render() {
    const { email, submittingForm: submitting, subscribed } = this.state
    return (
      <div>
        <h3>Iscriviti alla nostra newsletter gratuita!</h3>
        <p>
          Ti manderemo consigli, curiosità, e strategie che ti aiuteranno ad
          investire e a migliorare la tua vita finanziaria.
        </p>
        <form
          onSubmit={this._handleSubmit}
          style={{ display: subscribed ? "none" : "block" }}
        >
          <FormContainer>
            <input
              type="email"
              name="email"
              value={email}
              placeholder="Il tuo indirizzo email"
              onChange={this._handleChange}
            />
            <button
              type="submit"
              disabled={submitting}
              className="button-primary"
            >
              <span style={{ display: submitting ? "none" : "block" }}>
                Conferma
              </span>
              <div style={{ display: submitting ? "block" : "none" }}>
                <i className="fas fa-spinner-third fa-spin"></i>
              </div>
            </button>
          </FormContainer>
        </form>
        <p style={{ display: subscribed ? "block" : "none", color: "green" }}>
          Grazie per esserti iscritto/a!
        </p>
        <p className="text-small">
          Non preoccuparti, non ti invieremo mai spam!
        </p>
      </div>
    )
  }
}

const NewsletterForm = props => {
  return (
    <NewsletterContainer id="newsletter" {...props}>
      <NewsletterContent>
        <NewsletterImage>
          <Image source="newsletter-image.png" alt="newsletter logo" />
        </NewsletterImage>
        <ConvertKitForm />
      </NewsletterContent>
    </NewsletterContainer>
  )
}

export default NewsletterForm
