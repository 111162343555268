import React from "react"

import Page from "../components/page"
import Image from "../components/image"
import { SectionLarge } from "../components/elements"
import { ThreeColumnsGrid } from "../components/containers"
import NewsletterForm from "../components/newsletter-form"

const AboutPage = () => (
  <Page title="Chi siamo">
    <article className="full-width">
      <section>
        <Image source="about_us-podcasting.jpeg" alt="Noi nel nostro studio" />
        <p style={{ marginTop: "64px" }}>
          Ciao a tutti, siamo Gianluca e Jessica, investitori in erba verso la
          libertà finanziaria.
        </p>
        <p>
          Su questo sito e tramite il <a href="/podcast">podcast</a>,
          condividiamo il nostro percorso e le nostre conoscenze per investire
          in maniera sicura seguendo le orme dei grandi maestri come Warren
          Buffett.
        </p>
        <h3>Qualcosa su di noi</h3>
        <p>
          Viviamo a Londra da oltre 6 anni, lavoriamo in tech e nessuno dei due
          ha un background in finanza o economia. Da quando abbiamo iniziato a
          lavorare, abbiamo iniziato anche a risparmiare una percentuale del
          nostro guadagno, regolarmente, ogni mese. Per lungo tempo abbiamo
          semplicemente lasciato quei soldi in banca, fino a quando non ci siamo
          finalmente resi conto che non stavamo facendo fruttare al meglio i
          nostri risparmi e{" "}
          <a href="/blog/la-importanza-di-investire">
            che l'inflazione ci fa perdere soldi indirettamente
          </a>
          . 😱
        </p>
        <p>
          Purtroppo, finanza e gestione ottimale dei risparmi non sono materie
          che vengono insegnate a scuola in maniera rigorosa o argomenti di cui
          si parli abbastanza in famiglia. Abbiamo però trovato le risposte a
          tutti i nostri perché leggendo vari libri, guardando video e,
          soprattutto, imparando dai più grandi investitori di tutti i tempi
          come Warren Buffett, non a caso uno tra gli uomini più ricchi al
          mondo.
        </p>
        <p>
          Ci siamo ritrovati così tutti i giorni a parlare di investimenti e
          ripetere spesso gli stessi concetti, soprattutto Gianluca che ha
          iniziato il suo percorso per primo.
        </p>
        <p>
          Per questo abbiamo deciso di creare Investitori Ribelli, un progetto
          dove poter condividere la nostra esperienza, sperando di chiarire
          meglio le nostre idee, ma anche di aiutare altre persone che possono
          trovarsi nella nostra stessa situazione e non sanno bene né come
          intraprendere questo percorso di investimenti e né come farlo in
          maniera intelligente.
        </p>
      </section>
      <SectionLarge>
        <ThreeColumnsGrid>
          <Image
            source="about-seeking-wisdom.jpg"
            alt="Libro - Seeking Wisdom"
          />
          <Image
            source="about-poor-charlie-almanack.jpg"
            alt="Libro - Poor Charlie's Almanack"
          />
          <Image
            source="about-interpreting-financial-statements.jpg"
            alt="Libro - The Interpretation of Financial Statements"
          />
        </ThreeColumnsGrid>
      </SectionLarge>
      <section>
        <h3>Che cosa trovi su Investitori Ribelli?</h3>
        <p>
          Su Investitori Ribelli trovi <a href="/blog">articoli</a>,{" "}
          <a href="/risorse">risorse</a>, e materiali utili per intraprendere il
          tuo percorso educativo e 'spirituale' verso la libertà finanziaria.
        </p>
        <p>
          Investitori Ribelli non fa per te se sei in cerca di scorciatoie per
          diventare ricco nel minor tempo possibile. La nostra filosofia di
          investimenti, infatti, è ispirata alla metodologia del 'Value
          Investing' che probabilmente ti farà diventare ricco lentamente
          attraverso uno studio meticoloso del mercato, delle aziende in cui
          investire e, soprattutto, l'esercizio della pazienza di aspettare il
          momento giusto per impiegare il proprio capitale.
        </p>
        <h3>Che significa investire?</h3>
        <p>
          Spesso, quando si parla di investire, si tende ad associare una
          connotazione negativa al concetto. Si pensa, infatti, ad un'attività
          paragonabile al gioco d'azzardo, specialmente quando riguarda i
          mercati finanziari, pensando che possa comportare rischi altissimi con
          possibilità di 'vincere' limitate.
        </p>
        <p>
          In realtà, citando Warren Buffett, "Il rischio nasce dal non sapere
          cosa stai facendo."
        </p>
        <p>
          Con la giusta educazione finanziaria, è possibile investire il proprio
          denaro in maniera intelligente per poter costruire o acquistare
          attività produttive oggi che ci diano una ragionevole aspettativa di
          guadagno in futuro.
        </p>
        <h3>Perché investire e non mettere i soldi sotto il materasso?</h3>
        <p>
          Non investire significa semplicemente fare una scelta che distrugge i
          nostri risparmi.
        </p>
        <p>
          Il problema sta nel fatto che, a causa dell'inflazione, i risparmi
          tenuti in banca, nel salvadanaio, o sotto al materasso perdono di
          valore nel tempo. Investendoli in aziende meravigliose, invece, ci
          assicureremo di non perdere soldi.
        </p>
        <h3>Vuoi cominciare?</h3>
        <p>
          <ul>
            <li>
              Leggi di più sulla magia dell'interesse composto{" "}
              <a href="/blog/la-importanza-di-investire">
                <strong>qui</strong>
              </a>
            </li>
            <li>
              Impara a investire con successo leggendo{" "}
              <a href="/risorse">
                <strong>questi libri</strong>
              </a>
            </li>
            <li>
              Ascolta il nostro{" "}
              <a href="/podcast">
                <strong>podcast</strong>
              </a>
            </li>
            <li>
              Iscriviti alla{" "}
              <a href="#newsletter">
                <strong>newsletter</strong>
              </a>{" "}
              per restare sempre aggiornato
            </li>
          </ul>
        </p>
      </section>
    </article>
    <NewsletterForm style={{ margin: "64px" }} />
  </Page>
)

export default AboutPage
